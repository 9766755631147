import request from '@/utils/request';

//列表
export function fetchList(data) {
  return request({
    url : '/user/file/UserUploadFileList.php',
    method : 'post',
    data : data
  })
}

//批量设置文件价格
export function batchCheckFile(data) {
  return request({
    url : '/user/file/userUploadFileBatchCheck.php',
    method : 'post',
    data : data
  })
}

